import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Button, Drawer } from 'antd'
import { LogoutOutlined, MenuOutlined } from '@ant-design/icons'

import EALogo from 'Assets/easyassist-logo.png'

import Menu from 'Components/Layout/Navigation/Menu'

import useAcesso from 'Hooks/acesso/useAcesso'
import MenuSearch from '../MenuSearch'
import paths from '../../paths'

import './MenuDrawer.less'

const MenuDrawer = () => {
  const { logout } = useAcesso()
  const location = useLocation()
  const [isMenuVisible, setIsMenuVisible] = useState(false)
  const [usuarioNome, setUsuarioNome] = useState('')

  useEffect(() => {
    const nome = window.localStorage.getItem('usuario_nome')
    if (nome) {
      setUsuarioNome(nome)
    }
  }, [])

  const hideMenu = () => {
    setIsMenuVisible(false)
  }

  const closeMenuAtLocationChange = () => {
    if (isMenuVisible) {
      hideMenu()
    }
  }

  const showMenu = () => {
    setIsMenuVisible(true)
  }

  useEffect(closeMenuAtLocationChange, [location])

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginTop: '0px',
        padding: '0 16px',
        backgroundColor: '#ff4d4f',
        color: '#fff'
      }}
    >
      <MenuOutlined
        className="menu-drawer__trigger"
        data-testid="menu-drawer-trigger"
        onClick={showMenu}
        style={{ marginRight: '16px', marginLeft: '-8px' }}
      />

      <MenuSearch
        menuData={paths}
        style={{
          color: '#fff',
          lineHeight: '32px',
          paddingTop: '2px',
          marginLeft: '0px',
          height: '32px',
          display: 'flex',
          alignItems: 'center'
        }}
      />

      <Drawer
        bodyStyle={{ padding: 0 }}
        className="menu-drawer__drawer"
        data-testid="menu-drawer-drawer"
        footer={
          <Button block icon={<LogoutOutlined />} onClick={logout}>
            Sair
          </Button>
        }
        footerStyle={{ display: 'flex', alignItems: 'center', height: '64px' }}
        headerStyle={{ flex: 'none', height: '64px', padding: '16px' }}
        placement="left"
        title={
          <img alt="Easy@assist logo" src={EALogo} style={{ width: '100%' }} />
        }
        visible={isMenuVisible}
        onClose={hideMenu}
        width="25.625rem"
      >
        <Menu />
      </Drawer>

      {usuarioNome && (
        <div
          style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}
        >
          <span style={{ fontWeight: 'bold', marginRight: '8px' }}>
            Bem-vindo, {usuarioNome}!
          </span>

          <span
            style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              backgroundColor: 'green',
              borderRadius: '50%',
              marginLeft: '4px'
            }}
          />
        </div>
      )}
    </div>
  )
}

export default MenuDrawer
