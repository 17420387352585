import { Outlet } from 'react-router-dom'
import { Layout } from 'antd'

import Breadcrumb from './components/Layout/Navigation/Breadcrumb'
import MenuDrawer from './components/Layout/Navigation/Menu/Drawer'

import ApiErrorHandlerProvider from './components/ApiErrorHandler/Provider'

import './App.less'

const { Content, Header } = Layout

const App = () => (
  <Layout>
    <Header className="app__header">
      <MenuDrawer />
    </Header>

    <Content className="app__content">
      <Breadcrumb />

      <ApiErrorHandlerProvider>
        <Outlet />
      </ApiErrorHandlerProvider>
    </Content>
  </Layout>
)

export default App
