import api from 'Services/api'

const controleAcessoApiService = {
  listBy({ idestabelecimento, idusuario }) {
    return api.get(
      `/cadastro/estabelecimento/${idestabelecimento}/usuario/${idusuario}/controleacesso/`
    )
  },
  updateBy({ idestabelecimento, idusuario }, operacoes) {
    return api.post(
      `/cadastro/estabelecimento/${idestabelecimento}/usuario/${idusuario}/controleacesso/`,
      operacoes
    )
  }
}

export default controleAcessoApiService
