import { Button, Col, Form, Row, Select } from 'antd'
import PropTypes from 'prop-types'

import EstabelecimentoSelect from 'Components/Estabelecimento/Select'
import EACard from 'Components/UI/EACard'

import useEstabelecimentos from 'Hooks/estabelecimento/useEstabelecimentos'
import useUsuarios from 'Hooks/usuario/useUsuarios'

const { useForm } = Form
const { Option } = Select

const propTypes = {
  onChangeEstabelecimento: PropTypes.func.isRequired,
  onChangeUsuario: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

const ControleAcessoFilter = ({
  onChangeEstabelecimento,
  onChangeUsuario,
  onSubmit
}) => {
  const { estabelecimentosRequest } = useEstabelecimentos()
  const [form] = useForm()
  const { usuariosRequest } = useUsuarios()

  const usuarios = Array.from(usuariosRequest.data).sort((a, b) =>
    a.login.toLowerCase() < b.login.toLowerCase() ? -1 : 1
  )

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <EACard
        actions={[
          <Button htmlType="submit" type="primary">
            Filtrar
          </Button>
        ]}
        loading={estabelecimentosRequest.isLoading || usuariosRequest.isLoading}
        title="Controle de acesso de usuários"
      >
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <EstabelecimentoSelect
              antdFormItemProps={{
                rules: [{ required: true, message: 'Campo obrigatório.' }]
              }}
              antdSelectProps={{
                onChange: onChangeEstabelecimento
              }}
              estabelecimentos={estabelecimentosRequest.data}
            />
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              label="Usuário"
              name="idusuario"
              rules={[{ required: true, message: 'Campo obrigatório.' }]}
            >
              <Select
                filterOption={(chavePesquisa, option) =>
                  option.children
                    .toLowerCase()
                    .includes(chavePesquisa.toLowerCase())
                }
                showSearch
                onChange={onChangeUsuario}
              >
                {usuarios.map((usuario) => {
                  const { idusuario, login } = usuario
                  return (
                    <Option key={idusuario} value={idusuario}>
                      {login}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </EACard>
    </Form>
  )
}

ControleAcessoFilter.propTypes = propTypes

export default ControleAcessoFilter
