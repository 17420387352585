/* eslint-disable no-console */
import { useState } from 'react'

import ajustarDisponibilidadeProduto from 'Hooks/disponibilidadeProduto/ajustarDisponibilidadeProduto'

import useApiErrorHandler from 'Hooks/apiErrorHandler/useApiErrorHandler'

import disponibilidadeProdutoApiService from 'Services/api/disponibilidadeProduto'

const useDisponibilidadeProdutos = () => {
  const { handleApiError } = useApiErrorHandler()
  const [data, setData] = useState([])
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const fetchDisponibilidadeProdutos = (filtros) => {
    setIsLoading(true)

    return disponibilidadeProdutoApiService
      .listBy(filtros)
      .then((response) => response.data)
      .then((produtos) => produtos.map(ajustarDisponibilidadeProduto))
      .then(setData)
      .catch((error) => {
        if (error.response.status === 403) {
          handleApiError(error)
        }
        console.log(error)
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    disponibilidadeProdutosRequest: { data, hasError, isLoading },
    fetchDisponibilidadeProdutos
  }
}

export default useDisponibilidadeProdutos
