/* eslint-disable react/jsx-no-constructed-context-values */
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Modal } from 'antd'
import PropTypes from 'prop-types'

import AcessoContext from 'Components/Acesso/Context'

import api from 'Services/api'
import acessoApiService from 'Services/api/acesso'

const propTypes = {
  children: PropTypes.element.isRequired
}

const isTokenAcessoSalvo = () => {
  const accessToken = window.localStorage.getItem('earetaguarda_access_token')
  return !!accessToken
}

const AcessoProvider = ({ children }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [isAuthenticated, setIsAuthenticated] = useState(isTokenAcessoSalvo)

  const login = (credentials) => {
    const authenticateUser = () => {
      setIsAuthenticated(true)
    }

    const redirectToPreviousLocationOrIndex = () => {
      const from = location.state?.from?.pathname || '/'
      navigate(from, { replace: true })
    }

    const saveTokensAndUpdateApiAuthorizationHeader = (tokens) => {
      window.localStorage.setItem('earetaguarda_access_token', tokens.access)
      window.localStorage.setItem('earetaguarda_refresh_token', tokens.refresh)

      api.updateAuthorizationHeader(tokens.access)
    }

    const exibirMensagemDeBoasVindas = () => {
      Modal.info({
        content:
          'Seja bem-vindo ao novo EasyAssist Web! Fique à vontade para explorar todas as funcionalidades que oferecemos. Descubra como tornamos a sua experiência ainda melhor.',
        okButtonProps: { type: null },
        title: 'Novo EasyAssist Web',
        width: 480
      })
    }

    return acessoApiService
      .login(credentials)
      .then((response) => response.data)
      .then(saveTokensAndUpdateApiAuthorizationHeader)
      .then(authenticateUser)
      .then(redirectToPreviousLocationOrIndex)
      .then(exibirMensagemDeBoasVindas)
  }

  const logout = () => {
    const removeTokens = () => {
      window.localStorage.removeItem('earetaguarda_access_token')
      window.localStorage.removeItem('earetaguarda_refresh_token')
    }

    removeTokens()
    setIsAuthenticated(false)
    window.location.href = '/login'
  }

  return (
    <AcessoContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AcessoContext.Provider>
  )
}

AcessoProvider.propTypes = propTypes

export default AcessoProvider
