/* eslint-disable react/jsx-props-no-spreading */
import { Card, Spin } from 'antd'
import PropTypes from 'prop-types'

import classnames from 'classnames'

import './EACard.less'

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  className: PropTypes.string,
  loading: PropTypes.bool,
  title: PropTypes.string
}

const defaultProps = {
  className: null,
  loading: false,
  title: 'Formulário de cadastro'
}

const EACard = ({ className, children, loading, title, ...antdCardProps }) => (
  <Spin spinning={loading}>
    <Card
      className={classnames('ea-card__container', className)}
      title={title}
      {...antdCardProps}
    >
      {children}
    </Card>
  </Spin>
)

EACard.propTypes = propTypes
EACard.defaultProps = defaultProps

export default EACard
