import { Button, Result } from 'antd'

const EAUncaughtErrorFeedback = () => {
  const reloadPage = () => {
    window.location.reload()
  }

  return (
    <Result
      extra={<Button onClick={reloadPage}>Recarregar página</Button>}
      status="error"
      subTitle="Não se preocupe. Já estamos resolvendo o problema."
      title="Ocorreu um erro durante a operação."
    />
  )
}

export default EAUncaughtErrorFeedback
