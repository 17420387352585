/* eslint-disable no-console */
import { useEffect, useState } from 'react'

import ajustarUsuario from 'Hooks/usuario/ajustarUsuario'

import usuarioApiService from 'Services/api/usuario'

const useUsuarios = () => {
  const [data, setData] = useState([])
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    usuarioApiService
      .list()
      .then((response) => response.data)
      .then((usuarios) => usuarios.map(ajustarUsuario))
      .then(setData)
      .catch((error) => {
        console.log(error)
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return {
    usuariosRequest: { data, hasError, isLoading }
  }
}

export default useUsuarios
