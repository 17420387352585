/* eslint-disable no-console */
import { useEffect, useState } from 'react'

import ajustarOperacao from 'Hooks/operacao/ajustarOperacao'

import operacaoApiService from 'Services/api/operacao'

const useOperacoes = () => {
  const [data, setData] = useState([])
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    operacaoApiService
      .list()
      .then((response) => response.data)
      .then((operacoes) => operacoes.map(ajustarOperacao))
      .then(setData)
      .catch((error) => {
        console.log(error)
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return {
    operacoesRequest: { data, hasError, isLoading }
  }
}

export default useOperacoes
