import { useEffect, useState } from 'react'
import { Button, message, Spin } from 'antd'

import DisponibilidadeProdutoFilter from 'Components/DisponibilidadeProduto/Filter'
import DisponibilidadeProdutoTable from 'Components/DisponibilidadeProduto/Table'

import EAFloatingActionBar from 'Components/UI/EAFloatingActionBar'
import EARequestErrorResult from 'Components/UI/EARequestErrorResult'

import useApiErrorHandler from 'Hooks/apiErrorHandler/useApiErrorHandler'
import useDisponibilidadeProdutos from 'Hooks/disponibilidadeProduto/useDisponibilidadeProdutos'

import disponibilidadeProdutoApiService from 'Services/api/disponibilidadeProduto'

const DisponibilidadeProdutoPage = () => {
  const { handleApiError } = useApiErrorHandler()
  const { disponibilidadeProdutosRequest, fetchDisponibilidadeProdutos } =
    useDisponibilidadeProdutos()
  const [
    hasSubmittedDisponibilidadeProdutoFilter,
    setHasSubmittedDisponibilidadeProdutoFilter
  ] = useState(false)
  const [idEstabelecimentoSelecionado, setIdEstabelecimentoSelecionado] =
    useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [produtos, setProdutos] = useState([])

  useEffect(() => {
    setProdutos(disponibilidadeProdutosRequest.data)
  }, [disponibilidadeProdutosRequest.data])

  const atualizarDisponibilidadeProdutos = () => {
    setIsLoading(true)

    disponibilidadeProdutoApiService
      .updateBy(idEstabelecimentoSelecionado, produtos)
      .then(() => {
        message.success(
          'A disponibilidade dos produtos foi atualizada com sucesso.'
        )
      })
      .catch((error) => {
        handleApiError(
          error,
          'Erro ao tentar atualizar a disponibilidade dos produtos.'
        )
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const alterarEstabelecimentoEResetarResultados = (idestabelecimento) => {
    setIdEstabelecimentoSelecionado(idestabelecimento)

    if (hasSubmittedDisponibilidadeProdutoFilter) {
      message.info(
        'É necessário filtrar novamente os produtos após a alteração do estabelecimento.'
      )
      setHasSubmittedDisponibilidadeProdutoFilter(false)
      setProdutos([])
    }
  }

  if (disponibilidadeProdutosRequest.hasError) {
    return (
      <EARequestErrorResult title="Erro ao tentar obter os dados de disponibilidade dos produtos." />
    )
  }

  return (
    <Spin spinning={isLoading || disponibilidadeProdutosRequest.isLoading}>
      <DisponibilidadeProdutoFilter
        onChangeEstabelecimento={alterarEstabelecimentoEResetarResultados}
        onSubmit={(filtros) => {
          fetchDisponibilidadeProdutos(filtros).then(() => {
            setHasSubmittedDisponibilidadeProdutoFilter(true)
          })
        }}
      />

      {hasSubmittedDisponibilidadeProdutoFilter && (
        <DisponibilidadeProdutoTable
          produtos={produtos}
          onChange={setProdutos}
        />
      )}

      {produtos.length > 0 && (
        <EAFloatingActionBar>
          <Button type="primary" onClick={atualizarDisponibilidadeProdutos}>
            Atualizar
          </Button>
        </EAFloatingActionBar>
      )}
    </Spin>
  )
}

export default DisponibilidadeProdutoPage
