import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Menu as AntdMenu, Typography } from 'antd'
import { StarOutlined, StarFilled } from '@ant-design/icons'

import paths from 'Components/Layout/Navigation/paths'
import { getArrayOfPaths } from 'Components/Layout/Navigation/utils'

import './Menu.less'

const { Text } = Typography

const loadFavoritesFromLocalStorage = (user) => {
  const storedFavorites = localStorage.getItem(`favorites_${user}`)
  return storedFavorites ? JSON.parse(storedFavorites) : []
}

const Menu = () => {
  const location = useLocation()
  const usuario =
    localStorage.getItem('earetaguarda_usuario_login') || 'defaultUser'
  const [openKeys, setOpenKeys] = useState([])
  const [favorites, setFavorites] = useState(
    loadFavoritesFromLocalStorage(usuario)
  )

  useEffect(() => {
    localStorage.setItem(`favorites_${usuario}`, JSON.stringify(favorites))
  }, [favorites, usuario])

  const handleFavorite = (path) => {
    const parent = paths.find(
      (parentPath) =>
        parentPath.children &&
        parentPath.children.some((child) => child.key === path.key)
    )

    const favoriteItem = {
      ...path,
      parentLabel: parent?.label || null
    }

    setFavorites((prevFavorites) => {
      const isAlreadyFavorited = prevFavorites.some(
        (fav) => fav.key === path.key
      )

      if (isAlreadyFavorited) {
        return prevFavorites.filter((fav) => fav.key !== path.key)
      }

      const updatedFavorites = [...prevFavorites, favoriteItem]

      return updatedFavorites.sort((a, b) => {
        if (a.parentLabel && b.parentLabel && a.parentLabel !== b.parentLabel) {
          return a.parentLabel.localeCompare(b.parentLabel)
        }

        if (a.parentLabel === b.parentLabel) {
          return a.label.localeCompare(b.label)
        }
        return 0
      })
    })
  }

  const renderMenuItems = (path) => {
    const { children } = path

    if (children) {
      return {
        ...path,
        children: children.map(renderMenuItems)
      }
    }

    const isFavorite = favorites.some((fav) => fav.key === path.key)

    return {
      ...path,
      label: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'relative'
          }}
        >
          <Link to={path.key} onClick={(e) => e.stopPropagation()}>
            {path.label}
          </Link>
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleFavorite(path)
            }}
            style={{
              border: 'none',
              background: 'transparent',
              cursor: 'pointer',
              marginLeft: '8px',
              padding: '0',
              zIndex: 10
            }}
            aria-label={
              isFavorite ? 'Remover dos favoritos' : 'Adicionar aos favoritos'
            }
          >
            {isFavorite ? <StarFilled /> : <StarOutlined />}
          </button>
        </div>
      )
    }
  }

  const items = [...paths.map(renderMenuItems)]

  const arrayOfPaths = getArrayOfPaths(location.pathname)

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key))
    setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
  }

  return (
    <div>
      {favorites.length > 0 && (
        <AntdMenu
          className="menu__component"
          style={{
            backgroundColor: '#FF6347'
          }}
          items={[
            {
              key: 'favorites',
              label: (
                <div>
                  <StarFilled
                    style={{
                      marginRight: '0.20rem',
                      color: '#FFD700'
                    }}
                  />
                  <Text
                    style={{
                      color: '#FFFF00',
                      fontSize: '1rem'
                    }}
                  >
                    Meu menu
                  </Text>
                </div>
              ),
              children: favorites.map((fav) => ({
                key: fav.key,
                label: (
                  <Link to={fav.key}>
                    {fav.parentLabel
                      ? `${fav.parentLabel} > ${fav.label}`
                      : fav.label}
                  </Link>
                )
              }))
            }
          ]}
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
        />
      )}

      <AntdMenu
        className="menu__component"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        defaultOpenKeys={arrayOfPaths.slice(0, -1)}
        defaultSelectedKeys={
          arrayOfPaths.length ? arrayOfPaths.slice(-1) : ['/']
        }
        items={items}
        mode="inline"
      />
    </div>
  )
}

export default Menu
