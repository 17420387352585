import { useState } from 'react'
import { message } from 'antd'

import AcessoLoginForm from 'Components/Acesso/Login/Form'

import useAcesso from 'Hooks/acesso/useAcesso'

import './AcessoLoginPage.css'

const AcessoLoginPage = () => {
  const { login } = useAcesso()
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = (credentials) => {
    setIsLoading(true)

    login(credentials).catch((error) => {
      if (error.response.status === 401) {
        const detail =
          error.response?.data?.detail || 'As credenciais são inválidas.'

        message.error(detail)
      } else {
        message.error('Não foi possível entrar no sistema.')
      }

      setIsLoading(false)
    })
  }

  return (
    <div className="acesso-login-page__container">
      <AcessoLoginForm loading={isLoading} onSubmit={handleSubmit} />
    </div>
  )
}

export default AcessoLoginPage
