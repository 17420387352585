import api from 'Services/api'

const disponibilidadeProdutoApiService = {
  listBy({ idestabelecimento, idgrupoprincipal }) {
    let url = `/cadastro/estabelecimento/${idestabelecimento}/disponibilidade-produtos/`

    if (idgrupoprincipal) {
      url = `${url}?idgrupoprincipal=${idgrupoprincipal}`
    }

    return api.get(url)
  },
  updateBy(idestabelecimento, produtos) {
    return api.post(
      `/cadastro/estabelecimento/${idestabelecimento}/disponibilidade-produtos/`,
      produtos
    )
  }
}

export default disponibilidadeProdutoApiService
