/* eslint-disable camelcase */
const ajustarUsuario = (usuario) => {
  const { idtabelaprecopadrao_str, idusuario_str, ...propriedades } = usuario
  return {
    ...propriedades,
    idtabelaprecopadrao: idtabelaprecopadrao_str,
    idusuario: idusuario_str
  }
}

export default ajustarUsuario
