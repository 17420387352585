import { Navigate, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import useAcesso from 'Hooks/acesso/useAcesso'

const propTypes = {
  children: PropTypes.element.isRequired
}

const AcessoLoginRequired = ({ children }) => {
  const { isAuthenticated } = useAcesso()
  const location = useLocation()

  if (!isAuthenticated) {
    return <Navigate replace state={{ from: location }} to="/login" />
  }

  return children
}

AcessoLoginRequired.propTypes = propTypes

export default AcessoLoginRequired
