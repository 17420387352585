/* eslint-disable camelcase */
const ajustarOperacao = (operacao) => {
  const { idoperacao_str, ...propriedades } = operacao

  const suboperacoes = operacao.suboperacoes.map(ajustarOperacao)

  return { ...propriedades, idoperacao: idoperacao_str, suboperacoes }
}

export default ajustarOperacao
