/* eslint-disable no-console */
import { useState } from 'react'

import ajustarControleAcesso from 'Hooks/controleAcesso/ajustarControleAcesso'

import useApiErrorHandler from 'Hooks/apiErrorHandler/useApiErrorHandler'

import controleAcessoApiService from 'Services/api/controleAcesso'

const useControleAcesso = () => {
  const { handleApiError } = useApiErrorHandler()
  const [data, setData] = useState([])
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const fetchControleAcesso = (filtros) => {
    setIsLoading(true)

    return controleAcessoApiService
      .listBy(filtros)
      .then((response) => response.data)
      .then((operacoes) => operacoes.map(ajustarControleAcesso))
      .then(setData)
      .catch((error) => {
        if (error.response.status === 403) {
          handleApiError(error)
        }
        console.log(error)
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return {
    controleAcessoRequest: { data, hasError, isLoading },
    fetchControleAcesso
  }
}

export default useControleAcesso
