import axios from 'axios'

const acessoApiService = {
  login(credentials) {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/auth/token/`,
      credentials
    )
  },
  refresh(token) {
    return axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/auth/token/refresh/`,
      { refresh: token }
    )
  }
}

export default acessoApiService
