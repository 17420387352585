import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Select } from 'antd'
import PropTypes from 'prop-types'

const { Option, OptGroup } = Select

const propTypes = {
  menuData: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      children: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          label: PropTypes.string
        })
      )
    })
  ).isRequired
}

const MenuSearch = ({ menuData }) => {
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState('')
  const [selectedKey, setSelectedKey] = useState(null)

  const handleSearch = (value) => {
    setSearchValue(value)
    setSelectedKey(null)
  }

  const handleSelect = (key) => {
    navigate(key)
    setSearchValue('')
    setSelectedKey(null)
  }

  const renderOptions = (data) =>
    data.map((item) => {
      if (item.children && item.children.length > 0) {
        const childOptions = renderOptions(item.children)
        if (childOptions.length > 0) {
          return (
            <OptGroup key={item.label} label={item.label}>
              {childOptions}
            </OptGroup>
          )
        }
      }

      if (item.label.toLowerCase().includes(searchValue.toLowerCase())) {
        return (
          <Option key={item.key} value={item.key}>
            {item.label}
          </Option>
        )
      }

      return null
    })

  return (
    <Select
      optionFilterProp="children"
      placeholder="Busca rápida no menu"
      showSearch
      style={{ width: '300px', marginTop: '0px', marginLeft: '8px' }}
      value={selectedKey}
      onSearch={handleSearch}
      onSelect={handleSelect}
    >
      {renderOptions(menuData)}
    </Select>
  )
}

MenuSearch.propTypes = propTypes

export default MenuSearch
