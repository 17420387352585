import { Button, Input, Space } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

const addCustomSearchProps = (dataIndex) => {
  let searchInput = null

  return {
    filterDropdown: ({
      selectedKeys,
      setSelectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder="Pesquisar por..."
          ref={(input) => {
            searchInput = input
          }}
          style={{ display: 'block', marginBottom: 8 }}
          value={selectedKeys[0]}
          onChange={(event) => {
            setSelectedKeys(event.target.value ? [event.target.value] : [])
          }}
          onPressEnter={(event) => {
            event.stopPropagation()
            confirm()
          }}
        />

        <Space>
          <Button size="small" type="primary" onClick={() => confirm()}>
            Pesquisar
          </Button>

          <Button size="small" onClick={() => clearFilters()}>
            Limpar
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined
        }}
      />
    ),
    onFilter: (chavePesquisa, registro) => {
      const valor = registro[dataIndex]

      if (typeof valor === 'string') {
        const valorSemVirgula = valor.replace(',', '.')
        const pesquisaSemVirgula = chavePesquisa.replace(',', '.')
        return valorSemVirgula
          .toLowerCase()
          .includes(pesquisaSemVirgula.toLowerCase())
      }

      if (
        typeof valor === 'number' ||
        (typeof valor === 'string' && !Number.isNaN(Number(valor)))
      ) {
        const valorNumerico = parseFloat(valor.toString().replace(',', '.'))
        const pesquisaNumerica = parseFloat(
          chavePesquisa.toString().replace(',', '.')
        )
        return valorNumerico === pesquisaNumerica
      }

      return false
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.select(), 100)
      }
    }
  }
}

export default addCustomSearchProps
