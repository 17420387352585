/* eslint-disable camelcase */
const ajustarDisponibilidadeProduto = (produto) => {
  const { idproduto_str, ...propriedades } = produto
  return {
    ...propriedades,
    idproduto: idproduto_str
  }
}

export default ajustarDisponibilidadeProduto
