import { useNavigate } from 'react-router-dom'
import { Button, Result } from 'antd'

const NaoEncontradoPage = () => {
  const navigate = useNavigate()

  const goToIndex = () => {
    navigate('/')
  }

  return (
    <Result
      extra={<Button onClick={goToIndex}>Voltar para o início</Button>}
      subTitle="A página visitada não existe."
      title="Página não encontrada."
    />
  )
}

export default NaoEncontradoPage
