import { Button, Col, Form, Row, Select } from 'antd'
import PropTypes from 'prop-types'

import EstabelecimentoSelect from 'Components/Estabelecimento/Select'
import EACard from 'Components/UI/EACard'
import EARequestErrorResult from 'Components/UI/EARequestErrorResult'

import useEstabelecimentos from 'Hooks/estabelecimento/useEstabelecimentos'
import useGrupos from 'Hooks/grupo/useGrupos'

const { useForm } = Form
const { Option } = Select

const propTypes = {
  onChangeEstabelecimento: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}

const DisponibilidadeProdutoFilter = ({
  onChangeEstabelecimento,
  onSubmit
}) => {
  const { estabelecimentosRequest } = useEstabelecimentos()
  const [form] = useForm()
  const { gruposRequest } = useGrupos()

  const resetarIdGrupo = (idgrupo) => {
    if (idgrupo === undefined) {
      form.setFieldsValue({ idgrupo: null })
    }
  }

  const grupos = Array.from(gruposRequest.data).sort((a, b) =>
    a.descricao.toLowerCase() < b.descricao.toLowerCase() ? -1 : 1
  )

  if (estabelecimentosRequest.hasError || gruposRequest.hasError) {
    return <EARequestErrorResult />
  }

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <EACard
        actions={[
          <Button htmlType="submit" type="primary">
            Filtrar
          </Button>
        ]}
        loading={estabelecimentosRequest.isLoading || gruposRequest.isLoading}
        title="Disponibilidade de produtos"
      >
        <Row gutter={16}>
          <Col xs={24} md={12}>
            <EstabelecimentoSelect
              antdFormItemProps={{
                rules: [{ required: true, message: 'Campo obrigatório.' }]
              }}
              antdSelectProps={{ onChange: onChangeEstabelecimento }}
              estabelecimentos={estabelecimentosRequest.data}
            />
          </Col>

          <Col xs={24} md={12}>
            <Form.Item
              initialValue={null}
              label="Grupo de produtos"
              name="idgrupoprincipal"
            >
              <Select
                allowClear
                filterOption={(chavePesquisa, option) =>
                  option.children
                    .toLowerCase()
                    .includes(chavePesquisa.toLowerCase())
                }
                showSearch
                onChange={resetarIdGrupo}
              >
                {grupos.map((grupo) => {
                  const { descricao, idgrupo } = grupo
                  return (
                    <Option key={idgrupo} value={idgrupo}>
                      {descricao}
                    </Option>
                  )
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </EACard>
    </Form>
  )
}

DisponibilidadeProdutoFilter.propTypes = propTypes

export default DisponibilidadeProdutoFilter
