import PropTypes from 'prop-types'

const operacaoShape = {
  descricao: PropTypes.string,
  idoperacao: PropTypes.string
}

operacaoShape.suboperacoes = PropTypes.arrayOf(PropTypes.shape(operacaoShape))

const propTypes = {
  operacoes: PropTypes.arrayOf(PropTypes.shape(operacaoShape))
}

export default propTypes
