/* eslint-disable camelcase */
const ajustarEstabelecimento = (estabelecimento) => {
  const { idestabelecimento_str, pessoa, ...propriedades } = estabelecimento

  const {
    apelidooufantasia,
    cnpjoucpf,
    idpessoa_str,
    idpessoatitular_str,
    nomeourazaosocial
  } = pessoa

  return {
    ...propriedades,
    descricao: `${cnpjoucpf} - ${apelidooufantasia ?? nomeourazaosocial ?? ''}`,
    idestabelecimento: idestabelecimento_str,
    idpessoa: idpessoa_str,
    idpessoatitular: idpessoatitular_str,
    cnpjoucpf,
    apelidooufantasia,
    nomeourazaosocial
  }
}

export default ajustarEstabelecimento
