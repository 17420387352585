import { useEffect } from 'react'

import useAcesso from 'Hooks/acesso/useAcesso'

const AcessoLogoutPage = () => {
  const { logout } = useAcesso()

  useEffect(logout, [])

  return null
}

export default AcessoLogoutPage
