import { Spin } from 'antd'

import './EAFullPageLoader.less'

const EAFullPageLoader = () => (
  <div className="ea-full-page-loader__container">
    <Spin tip="Carregando a página" />
  </div>
)

export default EAFullPageLoader
