import { useNavigate } from 'react-router-dom'
import { Button, Result } from 'antd'

const NaoAutorizadoPage = () => {
  const navigate = useNavigate()

  const goToIndex = () => {
    navigate('/')
  }

  return (
    <Result
      extra={<Button onClick={goToIndex}>Voltar para o início</Button>}
      status="warning"
      subTitle="Você não tem permissão para continuar a operação."
      title="Não autorizado."
    />
  )
}

export default NaoAutorizadoPage
