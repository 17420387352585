/* eslint-disable no-param-reassign */
import axios from 'axios'

import acessoApiService from './acesso'

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
})

api.updateAuthorizationHeader = (token) => {
  api.defaults.headers.Authorization = `Bearer ${token}`
}

const accessToken = window.localStorage.getItem('earetaguarda_access_token')

if (accessToken) {
  api.updateAuthorizationHeader(accessToken)
}

let failedRequests = []
let isRefreshingToken = false

const cacheFailedRequest = (failedRequestConfig) =>
  new Promise((resolve) => {
    failedRequests.push({
      requestWithUpdatedAuthorizationHeader: () => {
        failedRequestConfig.headers.Authorization =
          api.defaults.headers.Authorization
        resolve(api(failedRequestConfig))
      }
    })
  })

const redirectToLogout = () => {
  window.location.href = '/logout'
}

const remakeFailedRequests = () => {
  failedRequests.forEach((failedRequest) =>
    failedRequest.requestWithUpdatedAuthorizationHeader()
  )
}

const saveAccessTokenAndUpdateApiAuthorizationHeader = (token) => {
  window.localStorage.setItem('earetaguarda_access_token', token)

  api.updateAuthorizationHeader(token)
}

const refreshAccessToken = () => {
  const refreshToken = window.localStorage.getItem('earetaguarda_refresh_token')
  isRefreshingToken = true

  acessoApiService
    .refresh(refreshToken)
    .then((response) => response.data.access)
    .then(saveAccessTokenAndUpdateApiAuthorizationHeader)
    .then(remakeFailedRequests)
    .catch(redirectToLogout)
    .finally(() => {
      failedRequests = []
      isRefreshingToken = false
    })
}

api.interceptors.response.use(
  (response) => response,
  (error) => {
    const isTokenInvalidError =
      error.response.status === 401 &&
      error.response.data?.code === 'token_not_valid'
    const failedRequestConfig = error.config

    if (isTokenInvalidError) {
      if (!isRefreshingToken) {
        refreshAccessToken()
      }
      return cacheFailedRequest(failedRequestConfig)
    }

    return Promise.reject(error)
  }
)

export default api
