/* eslint-disable camelcase */
import api from 'Services/api'

const GRUPO_PRODUTO_BASE_URL = '/cadastro/grupo'

const grupoApiService = {
  create(data) {
    return api.post(`${GRUPO_PRODUTO_BASE_URL}/`, data)
  },
  deleteById(idgrupo) {
    return api.delete(`${GRUPO_PRODUTO_BASE_URL}/${idgrupo}/`)
  },
  getById(idgrupo) {
    return api.get(`${GRUPO_PRODUTO_BASE_URL}/${idgrupo}/`)
  },
  list() {
    return api.get('/api/grupo/')
  },
  updateById(idopcao, data) {
    return api.put(`${GRUPO_PRODUTO_BASE_URL}/${idopcao}/`, data)
  }
}

export default grupoApiService
