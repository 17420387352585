/* eslint-disable */
import { Link, useLocation } from 'react-router-dom'
import { Breadcrumb as AntdBreadcrumb } from 'antd'
import { HomeOutlined } from '@ant-design/icons'

import paths from 'Components/Layout/Navigation/paths'

import { getArrayOfPaths } from 'Components/Layout/Navigation/utils'

import './Breadcrumb.less'

const getKeyLabelMapper = (paths, mapper = {}) =>
  paths.reduce((partialMapper, path) => {
    if (path.children) {
      partialMapper = getKeyLabelMapper(path.children, partialMapper)
    }

    return { ...partialMapper, [path.key]: path.label }
  }, mapper)

const labelMapper = getKeyLabelMapper(paths)

const Breadcrumb = () => {
  const location = useLocation()

  const breadcrumbItems = getArrayOfPaths(location.pathname)

  return (
    <AntdBreadcrumb className="breadcrumb__container">
      <AntdBreadcrumb.Item key="/">
        <Link to="/">
          <HomeOutlined />
        </Link>
      </AntdBreadcrumb.Item>

      {breadcrumbItems.length === 0 ? (
        <AntdBreadcrumb.Item key="/dashboard">Dashboard</AntdBreadcrumb.Item>
      ) : null}

      {breadcrumbItems.map((item) => (
        <AntdBreadcrumb.Item key={item}>
          {labelMapper[item]}
        </AntdBreadcrumb.Item>
      ))}
    </AntdBreadcrumb>
  )
}

export default Breadcrumb
