/* eslint-disable camelcase */
import { useState } from 'react'
import { Button, Divider, Radio, Tag } from 'antd'
import PropTypes from 'prop-types'

import EACard from 'Components/UI/EACard'
import EATable from 'Components/UI/EATable'

import addCustomSearchProps from 'Components/UI/EATable/addCustomSearchProps'

const propTypes = {
  produtos: PropTypes.arrayOf(
    PropTypes.shape({
      descricao: PropTypes.string,
      idproduto: PropTypes.string
    })
  ),
  onChange: PropTypes.func.isRequired
}

const defaultProps = {
  produtos: []
}

const DisponibilidadeProdutoTable = ({ produtos, onChange }) => {
  const [idsProdutosFiltrados, setIdsProdutosFitrados] = useState([])

  const alterarDisponibilidadeProduto = ({ idproduto, stindisponivel }) => {
    onChange(
      produtos.map((produto) => {
        if (produto.idproduto === idproduto) {
          return { ...produto, stindisponivel }
        }
        return produto
      })
    )
  }

  const alterarDisponibilidadeProdutos = (stindisponivel) => {
    onChange(
      produtos.map((produto) => {
        if (idsProdutosFiltrados.includes(produto.idproduto)) {
          return { ...produto, stindisponivel }
        }
        return produto
      })
    )
  }

  const columns = [
    {
      ...addCustomSearchProps('descricao'),
      dataIndex: 'descricao',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => (a.descricao < b.descricao ? -1 : 1),
      title: 'Descrição'
    },
    {
      align: 'center',
      dataIndex: 'delivery_web',
      filters: [
        { text: 'Não', value: false },
        { text: 'Sim', value: true }
      ],
      render: (delivery_web) =>
        delivery_web ? (
          <Tag color="success">Sim</Tag>
        ) : (
          <Tag color="error">Não</Tag>
        ),
      title: 'Delivery',
      onFilter: (valor, { delivery_web }) => delivery_web === valor
    },
    {
      align: 'center',
      filters: [
        { text: 'Não definida', value: -1 },
        { text: 'Disponível', value: 0 },
        { text: 'Indisponível, mas visível', value: 1 },
        { text: 'Indisponível e oculto', value: 2 }
      ],
      title: 'Disponibilidade',
      render: (produto) => (
        <Radio.Group
          buttonStyle="solid"
          value={produto.stindisponivel}
          onChange={(event) => {
            const { idproduto } = produto
            const stindisponivel = event.target.value

            alterarDisponibilidadeProduto({ idproduto, stindisponivel })
          }}
        >
          <Radio.Button value={0}>Disponível</Radio.Button>
          <Radio.Button value={1}>Indisponível, mas visível</Radio.Button>
          <Radio.Button value={2}>Indisponível e oculto</Radio.Button>
        </Radio.Group>
      ),
      onFilter: (valor, { stindisponivel }) => stindisponivel === valor
    }
  ]

  return (
    <>
      <EACard title="Ações com os produtos listados">
        <Button
          disabled={produtos.length === 0}
          type="primary"
          onClick={() => alterarDisponibilidadeProdutos(0)}
        >
          Disponibilizar todos
        </Button>
        <Divider type="vertical" />
        <Button
          disabled={produtos.length === 0}
          type="primary"
          onClick={() => alterarDisponibilidadeProdutos(1)}
        >
          Tornar todos indisponíveis, porém visíveis
        </Button>
        <Divider type="vertical" />
        <Button
          disabled={produtos.length === 0}
          type="primary"
          onClick={() => alterarDisponibilidadeProdutos(2)}
        >
          Tornar todos indisponíveis e ocultos
        </Button>
      </EACard>

      <EATable
        bordered
        className="ea-form-actions__margin"
        columns={columns}
        dataSource={produtos}
        pagination={{
          hideOnSinglePage: false,
          pageSizeOptions: ['10', '20', '50', '100'],
          position: ['bottomRight', 'topRight'],
          size: 'big',
          showSizeChanger: true,
          showTitle: false
        }}
        rowKey="idproduto"
        size="small"
        title={(currentPageData) => {
          const currentPageDataIds = currentPageData.map(
            ({ idproduto }) => idproduto
          )

          if (
            JSON.stringify(idsProdutosFiltrados) !==
            JSON.stringify(currentPageDataIds)
          ) {
            setIdsProdutosFitrados(currentPageDataIds)
          }

          return 'Produtos encontrados'
        }}
      />
    </>
  )
}

DisponibilidadeProdutoTable.propTypes = propTypes
DisponibilidadeProdutoTable.defaultProps = defaultProps

export default DisponibilidadeProdutoTable
