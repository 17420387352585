import { useEffect, useState } from 'react'
import { Button, message, Spin } from 'antd'

import ControleAcessoFilter from 'Components/ControleAcesso/Filter'
import ControleAcessoTree from 'Components/ControleAcesso/Tree'

import EAFloatingActionBar from 'Components/UI/EAFloatingActionBar'
import EARequestErrorResult from 'Components/UI/EARequestErrorResult'

import useControleAcesso from 'Hooks/controleAcesso/useControleAcesso'
import useOperacoes from 'Hooks/operacao/useOperacoes'

import controleAcessoApiService from 'Services/api/controleAcesso'

const ControleAcessoPage = () => {
  const { controleAcessoRequest, fetchControleAcesso } = useControleAcesso()
  const { operacoesRequest } = useOperacoes()
  const [
    hasSubmittedControleAcessoFilter,
    setHasSubmittedControleAcessoFilter
  ] = useState(false)
  const [idEstabelecimentoSelecionado, setIdEstabelecimentoSelecionado] =
    useState(null)
  const [idUsuarioSelecionado, setIdUsuarioSelecionado] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [operacoesUsuario, setOperacoesUsuario] = useState([])

  useEffect(() => {
    setOperacoesUsuario(
      controleAcessoRequest.data.map((operacao) => operacao.idoperacao)
    )
  }, [controleAcessoRequest.data])

  const filtrarOperacoesUsuario = (filtros) => {
    fetchControleAcesso(filtros).then(() => {
      setHasSubmittedControleAcessoFilter(true)
    })
  }

  const atualizarOperacoesUsuario = () => {
    const idestabelecimento = idEstabelecimentoSelecionado
    const idusuario = idUsuarioSelecionado
    const operacoes = operacoesUsuario.map((idoperacao) => ({
      idoperacao
    }))

    setIsLoading(true)

    controleAcessoApiService
      .updateBy({ idestabelecimento, idusuario }, operacoes)
      .then(() => {
        message.success(
          'As permissões do usuário foram atualizadas com sucesso.'
        )
      })
      .catch(() => {
        message.error('Erro ao tentar atualizar as permissões do usuário.')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const resetarPermissoesUsuario = () => {
    if (hasSubmittedControleAcessoFilter) {
      message.info(
        'É necessário filtrar novamente as permissões do usuário após a alteração dos filtros.'
      )
    }
    setHasSubmittedControleAcessoFilter(false)
    setOperacoesUsuario([])
  }

  const alterarEstabelecimentoEResetarPermissoesUsuario = (
    idestabelecimento
  ) => {
    setIdEstabelecimentoSelecionado(idestabelecimento)
    resetarPermissoesUsuario()
  }

  const alterarUsuarioEResetarPermissoesUsuario = (idusuario) => {
    setIdUsuarioSelecionado(idusuario)
    resetarPermissoesUsuario()
  }

  if (controleAcessoRequest.hasError || operacoesRequest.hasError) {
    return <EARequestErrorResult />
  }

  return (
    <Spin
      spinning={
        isLoading ||
        controleAcessoRequest.isLoading ||
        operacoesRequest.isLoading
      }
    >
      <ControleAcessoFilter
        onChangeEstabelecimento={
          alterarEstabelecimentoEResetarPermissoesUsuario
        }
        onChangeUsuario={alterarUsuarioEResetarPermissoesUsuario}
        onSubmit={filtrarOperacoesUsuario}
      />

      {hasSubmittedControleAcessoFilter && (
        <ControleAcessoTree
          operacoes={operacoesRequest.data}
          operacoesUsuario={operacoesUsuario}
          onChange={setOperacoesUsuario}
        />
      )}

      {operacoesUsuario.length > 0 && (
        <EAFloatingActionBar>
          <Button type="primary" onClick={atualizarOperacoesUsuario}>
            Atualizar
          </Button>
        </EAFloatingActionBar>
      )}
    </Spin>
  )
}

export default ControleAcessoPage
