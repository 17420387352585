/* eslint-disable no-console */
import { message } from 'antd'
import { useEffect, useState } from 'react'

import ajustarGrupo from 'Hooks/grupo/ajustarGrupo'

import grupoApiService from 'Services/api/grupo'

const useGrupos = () => {
  const [data, setData] = useState([])
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const fetchGrupos = () => {
    setIsLoading(true)

    grupoApiService
      .list()
      .then((response) => response.data)
      .then((grupos) => grupos.map(ajustarGrupo))
      .then(setData)
      .catch((error) => {
        if (error.response && error.response.status === 403) {
          message.error(
            'Usuario não possui permissão para listar o grupo de produtos.'
          )
        } else {
          message.error('Erro ao tentar listar o grupo de produtos.')
        }
        console.log(error)
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(fetchGrupos, [])

  return {
    fetchGrupos,
    gruposRequest: { data, hasError, isLoading }
  }
}

export default useGrupos
