import api from 'Services/api'

const USUARIO_BASE_URL = '/api/usuario'

const usuarioApiService = {
  list() {
    return api.get(`${USUARIO_BASE_URL}/`)
  }
}

export default usuarioApiService
