/* eslint-disable no-console */
import { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'

import ajustarEstabelecimento from 'Hooks/estabelecimento/ajustarEstabelecimento'

import estabelecimentoApiService from 'Services/api/estabelecimento'

const useEstabelecimentos = () => {
  const [data, setData] = useState([])
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    estabelecimentoApiService
      .listEstabelecimentos()
      .then((response) => response.data)
      .then((estabelecimentos) => estabelecimentos.map(ajustarEstabelecimento))
      .then(setData)
      .catch((error) => {
        Sentry.captureException(error)
        console.log(error)
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])

  return {
    estabelecimentosRequest: { data, hasError, isLoading }
  }
}

export default useEstabelecimentos
