import { Tree } from 'antd'

import EACard from 'Components/UI/EACard'

import propTypes from './ControleAcessoTree.types'

const ControleAcessoTree = ({ operacoes, operacoesUsuario, onChange }) => (
  <EACard title="Permissões de operação">
    <Tree
      checkable
      checkedKeys={operacoesUsuario}
      fieldNames={{
        children: 'suboperacoes',
        key: 'idoperacao',
        title: 'descricao'
      }}
      treeData={operacoes}
      onCheck={onChange}
    />
  </EACard>
)

ControleAcessoTree.propTypes = propTypes

export default ControleAcessoTree
