import api from 'Services/api'

const ESTABELECIMENTO_BASE_URL = '/cadastro/estabelecimento'

const estabelecimentoApiService = {
  getById(idestabelecimento) {
    return api.get(`${ESTABELECIMENTO_BASE_URL}/${idestabelecimento}/`)
  },
  list() {
    return api.get('/core/estabelecimento/me/')
  },
  listLicencas() {
    return api.get('/core/estabelecimento/licencas/')
  },
  updateById(idestabelecimento, data) {
    return api.put(`${ESTABELECIMENTO_BASE_URL}/${idestabelecimento}/`, data)
  },
  listEstabelecimentos() {
    return api.get(`${ESTABELECIMENTO_BASE_URL}/`)
  },
  replicatePrecos(origem, destino) {
    return api.post('/core/acoes/replicar_precos/', { origem, destino })
  }
}

export default estabelecimentoApiService
