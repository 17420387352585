/* eslint-disable react/jsx-props-no-spreading, react/prop-types */
import { Form, Select } from 'antd'
import PropTypes from 'prop-types'

const { Option } = Select

const propTypes = {
  estabelecimentos: PropTypes.arrayOf(
    PropTypes.shape({
      descricao: PropTypes.string,
      idestabelecimento: PropTypes.string
    })
  ).isRequired
}

const EstabelecimentoSelect = ({
  antdFormItemProps,
  antdSelectProps,
  estabelecimentos
}) => {
  const estabelecimentosOrdenados = Array.from(estabelecimentos).sort(
    (a, b) => {
      if (
        typeof a.apelidooufantasia === 'string' &&
        typeof b.apelidooufantasia === 'string'
      ) {
        return a.apelidooufantasia.toLowerCase() <
          b.apelidooufantasia.toLowerCase()
          ? -1
          : 1
      }

      if (
        typeof a.nomeourazaosocial === 'string' &&
        typeof b.nomeourazaosocial === 'string'
      ) {
        return a.nomeourazaosocial.toLowerCase() <
          b.nomeourazaosocial.toLowerCase()
          ? -1
          : 1
      }

      return 0
    }
  )

  return (
    <Form.Item
      label="Estabelecimento"
      name="idestabelecimento"
      {...antdFormItemProps}
    >
      <Select
        filterOption={(chavePesquisa, option) =>
          option.children.toLowerCase().includes(chavePesquisa.toLowerCase())
        }
        showSearch
        {...antdSelectProps}
      >
        {estabelecimentosOrdenados.map((estabelecimento) => (
          <Option key={estabelecimento.idestabelecimento}>
            {estabelecimento.descricao}
          </Option>
        ))}
      </Select>
    </Form.Item>
  )
}

EstabelecimentoSelect.propTypes = propTypes

export default EstabelecimentoSelect
