/* eslint-disable react/jsx-no-constructed-context-values */
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { message as antMessage } from 'antd'
import PropTypes from 'prop-types'

import ApiErrorHandlerContext from 'Components/ApiErrorHandler/Context'

import NaoAutorizadoPage from 'Pages/nao-autorizado'
import NaoEncontradoPage from 'Pages/nao-encontrado'

const propTypes = {
  children: PropTypes.element.isRequired
}

const ApiErrorHandlerProvider = ({ children }) => {
  const location = useLocation()
  const [errorType, setErrorType] = useState(null)

  useEffect(() => {
    if (errorType !== null) {
      setErrorType(null)
    }
  }, [location.pathname])

  const handleApiError = (error, message = 'An error occurred.') => {
    if (error.response.status === 403 || error.response.status === 404) {
      setErrorType(error.response.status)
      return
    }

    antMessage.error(message)
  }

  return (
    <ApiErrorHandlerContext.Provider value={{ handleApiError }}>
      {errorType === 403 && <NaoAutorizadoPage />}

      {errorType === 404 && <NaoEncontradoPage />}

      {errorType === null && children}
    </ApiErrorHandlerContext.Provider>
  )
}

ApiErrorHandlerProvider.propTypes = propTypes

export default ApiErrorHandlerProvider
