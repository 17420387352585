import PropTypes from 'prop-types'

import './EAFloatingActionBar.less'

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired
}

const EAFloatingActionBar = ({ children }) => (
  <div className="ea-floating-action-bar__container">{children}</div>
)

EAFloatingActionBar.propTypes = propTypes

export default EAFloatingActionBar
