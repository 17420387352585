/* eslint-disable react/jsx-props-no-spreading */
import { Table } from 'antd'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import './EATable.css'

const propTypes = {
  className: PropTypes.string
}

const defaultProps = {
  className: null
}

const EATable = ({ className, ...antdTableProps }) => (
  <Table
    bordered
    className={classNames('ea-table__container', className)}
    pagination={{
      hideOnSinglePage: true,
      pageSizeOptions: [10, 20, 50, 100],
      showSizeChanger: true
    }}
    {...antdTableProps}
  />
)

EATable.propTypes = propTypes
EATable.defaultProps = defaultProps

export default EATable
