import { Button, Result } from 'antd'
import PropTypes from 'prop-types'

const propTypes = {
  title: PropTypes.string
}

const defaultProps = {
  title: 'Erro ao carregar as dependências do formulário.'
}

const EAFormRequestError = ({ title }) => (
  <Result
    extra={
      <Button
        onClick={() => {
          window.location.reload()
        }}
      >
        Recarregar a página
      </Button>
    }
    status="error"
    subTitle="Por favor, tente novamente recarregando a página."
    title={title}
  />
)

EAFormRequestError.propTypes = propTypes
EAFormRequestError.defaultProps = defaultProps

export default EAFormRequestError
