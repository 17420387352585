/* eslint-disable camelcase */
const ajustarAppGrupoOrdem = (appGrupoOrdem) => {
  const { idestabelecimento_str, idgrupo_str, ...propriedades } = appGrupoOrdem

  return {
    ...propriedades,
    idestabelecimento: idestabelecimento_str,
    idgrupo: idgrupo_str
  }
}

const ajustarExcecao = (grupoEstabExcecao) => {
  const { idestabelecimento_str, idgrupo_str, ...propriedades } =
    grupoEstabExcecao

  return {
    ...propriedades,
    idestabelecimento: idestabelecimento_str,
    idgrupo: idgrupo_str
  }
}

const ajustarGrupoOpcao = (grupoOpcao) => {
  const {
    idgrupo_str,
    idgrupogrupoopcao_str,
    idgrupoopcao_str,
    ...propriedades
  } = grupoOpcao

  return {
    ...propriedades,
    idgrupo: idgrupo_str,
    idgrupogrupoopcao: idgrupogrupoopcao_str,
    idgrupoopcao: idgrupoopcao_str
  }
}

const ajustarIdioma = (idioma) => {
  const { idgrupo_str, idgrupoidioma_str, ididioma_str, ...propriedades } =
    idioma

  return {
    ...propriedades,
    idgrupo: idgrupo_str,
    idgrupoidioma: idgrupoidioma_str,
    ididioma: ididioma_str
  }
}

const ajustarImagem = (imagem) => {
  const { idgrupo_str, idimggrupo_str, ...propriedades } = imagem

  return {
    ...propriedades,
    idgrupo: idgrupo_str,
    idimggrupo: idimggrupo_str
  }
}

const ajustarImpressora = (impressora) => {
  const {
    idestabelecimento_str,
    idgrupo_str,
    idgrupoimpspool_str,
    ...propriedades
  } = impressora

  return {
    ...propriedades,
    idestabelecimento: idestabelecimento_str,
    idgrupo: idgrupo_str,
    idgrupoimpspool: idgrupoimpspool_str
  }
}

const ajustarProduto = (produto) => {
  const { idgrupo_str, idgrupoproduto_str, idproduto_str, ...propriedades } =
    produto

  return {
    ...propriedades,
    idgrupo: idgrupo_str,
    idgrupoproduto: idgrupoproduto_str,
    idproduto: idproduto_str
  }
}

const ajustarGrupo = (grupo) => {
  const {
    appgrupoordem_set = [],
    grupoestabexcecao_set = [],
    grupogrupoopcao_set = [],
    grupoidioma_set = [],
    grupoimpspool_set = [],
    grupoproduto_set = [],
    idgrupo_str,
    idgrupopai_str,
    imggrupo_set = [],
    ...propriedades
  } = grupo

  return {
    ...propriedades,
    appgrupoordem_set: appgrupoordem_set.map(ajustarAppGrupoOrdem),
    grupoestabexcecao_set: grupoestabexcecao_set.map(ajustarExcecao),
    grupogrupoopcao_set: grupogrupoopcao_set.map(ajustarGrupoOpcao),
    grupoidioma_set: grupoidioma_set.map(ajustarIdioma),
    grupoimpspool_set: grupoimpspool_set.map(ajustarImpressora),
    grupoproduto_set: grupoproduto_set.map(ajustarProduto),
    idgrupo: idgrupo_str,
    idgrupopai: idgrupopai_str,
    imggrupo_set: imggrupo_set.map(ajustarImagem)
  }
}

export default ajustarGrupo
